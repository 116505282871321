export const URLs = {
  DomainBodyUserApp:
    process.env.REACT_APP_ENV === 'prod'
      ? 'app.mindlyspace.com'
      : 'app-user-pwa.firebaseapp.com',
  DomainBodyAdminApp:
    process.env.REACT_APP_ENV === 'prod'
      ? 'admin.mindlyspace.com'
      : 'admin-therapist-pwa.firebaseapp.com',
};
