/**
 * Checking is user psychologist
 * @hint Getting psychologist snapshot. If it exists - it`s psychologist, in other way - user
 * @return Promise<boolean>
 */
import { firestore } from '../firebase';


export const checkIsPsychologist = async (
  userEmail: string,
): Promise<boolean> => {
  try {
    const psychologistSnapshotRef = firestore.collection(
      'Psychologists_public',
    );

    const snapshot = await psychologistSnapshotRef
      .where('email', '==', userEmail)
      .get();

    return !snapshot.empty;
  } catch (error) {
    console.error(`CHECK_IS_PSYCHOLOGIST_ERROR: ${error}`);
    return false;
  }
};
