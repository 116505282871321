import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AdminAppRoutes from './components/AdminAppRoutes/AdminAppRoutes';
import UserAppRoutes from './components/UserAppRoutes/UserAppRoutes';

const App: React.FC = () => {
  return (
    <Switch>
      <Route path="/userApp/:param" render={() => <UserAppRoutes />} />
      <Route path="/adminApp/:param" render={() => <AdminAppRoutes />} />

      <Redirect to="/" />
    </Switch>
  );
};

export default App;

