import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../UI/Button/Button';
import { DeepLinksScreen, DeepLinksText } from './AdminAppRoutes.styled';
import queryString from 'query-string';
import {
  DeepLinksContentBlock,
  MindlyLogo,
} from '../UserAppRoutes/UserAppRoutes.styled';
import { getMobileOperatingSystem } from '../UserAppRoutes/utils';
import MobileDetect from 'mobile-detect';
import { colorConstants } from '../../theme/mindly_constants';
import { URLs } from '../../constans/constans';

/**
 *
 * Component for redirecting users to pages depending on the device
 */
const AdminAppRoutes: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const parsedQuery = queryString.parse(history.location.search);
  const { mode, oobCode, error } = parsedQuery as {mode: string, oobCode: string, error: string};
  const detect = new MobileDetect(window.navigator.userAgent);
  const linkRef = useRef<HTMLAnchorElement>(null);

  const prefix = 'https';
  const appRoute = location.pathname.split('/')[2];

  const routeWithQuery = `${appRoute === 'videochat' ? 'consultations' : appRoute}?mode=${mode}&oobCode=${oobCode}`;
  const URL = `${prefix}://${URLs.DomainBodyAdminApp}/${routeWithQuery}`;

  useEffect(() => {
    if (appRoute === 'videochat') {
      if (!detect.phone()) {
        linkRef.current?.click();
      }
    }
  }, []);


  const getRestoreText = () => {
    switch (appRoute) {
      case 'schedule':
        if (error === 'OTHER_EMAIL_SYNC_ERROR') {
          return 'Данный email-адрес не зарегистрирован в приложении';
        }
        return 'Календарь синхронизирован!';
      default:
        break;
    }
  };

  return (
    <DeepLinksScreen>
      <DeepLinksContentBlock>
        <MindlyLogo>
          <img
            src='https://storage.googleapis.com/mindly-picture-storage/deeplinks/Mindly-logo.png'
            alt='logo'
          />
        </MindlyLogo>
        <DeepLinksText>{getRestoreText()}</DeepLinksText>
        {
          error === 'OTHER_EMAIL_SYNC_ERROR'
          && (
            <DeepLinksText>Вы можете синхронизировать только тот календарь, который связан с имейлом по которому Вы заходите в приложение</DeepLinksText>
          )
        }
        {getMobileOperatingSystem() == 'unknown' ? <div style={{ width: '330px' }}>
          <a ref={linkRef} href={`${prefix}://${URLs.DomainBodyAdminApp}`}>
            <Button color={colorConstants.blueMindlyColor}>
              Открыть сайт
            </Button>
          </a>
        </div> : <>
          <div style={{ width: '330px' }}>
            <div style={{ width: '330px' }}>
              <a href={URL}>
                <Button color={colorConstants.blueMindlyColor}>
                  {mode == 'openVideochat' ? 'Открыть приложение' : 'Вернуться в приложение'}
                </Button>
              </a>
            </div>
          </div>
        </>}
      </DeepLinksContentBlock>
    </DeepLinksScreen>
  );
};

export default AdminAppRoutes;
