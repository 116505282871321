import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../UI/Button/Button';
import {
  DeepLinksContentBlock,
  DeepLinksScreen,
  DeepLinksText,
  MindlyLogo,
} from './UserAppRoutes.styled';
import queryString from 'query-string';
import MobileDetect from 'mobile-detect';
import { checkIsPsychologist } from '../../api/checkIsPsychologist';
import { URLs } from '../../constans/constans';


/**
 * Component for redirecting users to pages depending on the device
 * @return JSX.Element
 */
const UserAppRoutes: React.FC = () => {
  const history = useHistory();
  const parsedQuery = queryString.parse(history.location.search);
  const { mode, oobCode, email } = parsedQuery;
  const userLinkRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [domainBody, setDomainBody] = useState(URLs.DomainBodyUserApp);
  const detect = new MobileDetect(window.navigator.userAgent);
  const prefix = 'https';

  useEffect(() => {
    if (email) {
      setIsLoading(true);
      checkIsPsychologist(email as string)
        .then((isPsychologist) => {
          setDomainBody(isPsychologist ? URLs.DomainBodyAdminApp : URLs.DomainBodyUserApp);
          setIsLoading(false);
        });
    }
  }, []);

  const routeWithQuery = `${mode === 'resetPassword'
    ? 'restorepassword'
    : mode === 'openVideochat'
      ? 'consultations'
      : 'signup/form'}?mode=${mode}&oobCode=${oobCode}&email=${email}`;
  const URL = `${prefix}://${domainBody}/${routeWithQuery}`;

  useEffect(() => {
    if (mode === 'openVideochat') {
      if (!detect.phone()) {
        userLinkRef.current?.click();
      }
    }
  }, []);

  const getRestoreText = () => {
    switch (mode) {
      case 'resetPassword':
        return 'Меняем Ваш пароль...';
      case 'verifyEmail':
        return 'Адрес почты успешно подтвержден!';
      default:
        break;
    }
  };

  if (mode === 'openVideochat') {
    return (
      <DeepLinksScreen>
        <DeepLinksContentBlock>
          <MindlyLogo>
            <img
              src='https://storage.googleapis.com/mindly-picture-storage/deeplinks/Mindly-logo.png'
              alt='logo'
            />
          </MindlyLogo>

          <div style={{ width: '330px' }}>
            <a href={URL}>
              <Button color={'#21ABD2'}>
                                Открыть приложение
              </Button>
            </a>
          </div>

        </DeepLinksContentBlock>
      </DeepLinksScreen>
    );
  }


  return (
    <DeepLinksScreen>
      <DeepLinksContentBlock>
        <MindlyLogo>
          <img
            src='https://storage.googleapis.com/mindly-picture-storage/deeplinks/Mindly-logo.png'
            alt='logo'
          />
        </MindlyLogo>
        <DeepLinksText>{getRestoreText()}</DeepLinksText>
        <div style={{ width: '330px' }}>
          <a href={URL}>
            <Button disabled={isLoading} color={'#21ABD2'}>
              {mode === 'verifyEmail'
                ? 'Продолжить регистрацию'
                : 'Восстановить пароль'}
            </Button>
          </a>
        </div>
      </DeepLinksContentBlock>
    </DeepLinksScreen>
  );
};

export default UserAppRoutes;
