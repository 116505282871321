import styled from 'styled-components';


// import { fontWeightConstants } from '../../theme/mindly_constants'

export const DeepLinksScreen = styled.div`
  height: 95vh;
  @media (min-width: 768px) {
    background: url("https://storage.googleapis.com/mindly-picture-storage/deeplinks/admin-desktop-bg.png")
      center center/cover no-repeat;
  }

  @media (max-width: 768px) {
    background: url("https://storage.googleapis.com/mindly-picture-storage/deeplinks/admin-mobile-bg.png")
      center center/cover no-repeat;
  }
`;

export const MindlyLogo = styled.div`
  margin-bottom: 48px;

  img {
    width: 142px;
    height: 39px;
  }
`;

export const DeepLinksText = styled.div`
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 48px;
`;

export const DeepLinksContentBlock = styled.div`
  padding: 0 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
