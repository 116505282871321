import styled from 'styled-components';
import { fontWeightConstants } from '../../theme/mindly_constants';


export const DeepLinksScreen = styled.div`
  height: 100vh;
  @media (min-width: 768px) {
    background: url("https://storage.googleapis.com/mindly-picture-storage/deeplinks/admin-desktop-bg.png")
      center center/cover no-repeat;
  }

  @media (max-width: 768px) {
    background: url("https://storage.googleapis.com/mindly-picture-storage/deeplinks/admin-mobile-bg.png")
      center center/cover no-repeat;
  }
`;

export const DeepLinksText = styled.div`
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: ${fontWeightConstants.semibold};
  margin-bottom: 48px;
`;
