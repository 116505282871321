import styled from 'styled-components';

export const Button = styled.button`
  text-align: center;
  font-size: 18px;
  padding: 10px 0;
  border-radius: 6px;
  border: none;
  background-color: ${(props) => props.color};
  cursor: pointer;
  color: white;
  width: 100%;
`;
