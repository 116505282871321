export const colorConstants = {
  primaryColor: '#087290',
  secondaryColor: '#CDE0E5',
  primaryTextColor: '#5B5B5B',
  secondaryTextColor: '#1D201F',
  AccentStrokeColor: '#EDEFF2',
  AccentCross: '#f1646f',
  AccentDisabledColor: '#C1C1C1',
  AccentCrossColor: '#A31621',
  AccentTickColor: '#478862',
  AccentInputBgColor: '#F5F9FF',
  AccentTabsBgColor: '#D6F2F9',
  accentAchieveBg: '#F2F2F2',
  blueMindlyColor: '#21ABD2',
  black: '#000',
};


export const fontWeightConstants = {
  bold: 700,
  semibold: 600,
  regular: 400,
};
