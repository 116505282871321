type OSType = 'iOS' | 'Android' | 'unknown';


declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    opera: any;
    MSStream: any;
  }
}

export const getMobileOperatingSystem = (): OSType => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window?.MSStream) {
    return 'iOS';
  }

  return 'unknown';
};
