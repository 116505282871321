import firebase from 'firebase';


const myFirebase = (() => {
  const firebaseConfigDevelopment = {
    apiKey: 'AIzaSyADqmCy3MVEa9axmJhjrPDgpVkLKxMGW-4',
    authDomain: 'ihub-projects-developmen-af75f.firebaseapp.com',
    projectId: 'ihub-projects-developmen-af75f',
    storageBucket: 'ihub-projects-developmen-af75f.appspot.com',
    messagingSenderId: '68673811523',
    appId: '1:68673811523:web:b7aaaa3861a303c65190f6',
    measurementId: 'G-T309202CVZ',
  };

  const firebaseConfigProduction = {
    apiKey: 'AIzaSyB8cUDkOLdAXWud_Rb-1nKbXk0NyRX0MYo',
    authDomain: 'ihub-projects-production.firebaseapp.com',
    projectId: 'ihub-projects-production',
    storageBucket: 'ihub-projects-production.appspot.com',
    messagingSenderId: '587217239273',
    appId: '1:587217239273:web:416024417a5241127b0478',
    measurementId: 'G-0RDJW93MND',
  };

  const dataBaseEnv
    = process.env.REACT_APP_ENV == 'prod'
      ? firebaseConfigProduction
      : firebaseConfigDevelopment;

  return firebase.initializeApp(dataBaseEnv);
})();

export const googleProvider = new firebase.auth.GoogleAuthProvider();

export const firestore = myFirebase.firestore();

export const auth = myFirebase.auth();
